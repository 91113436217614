import axios from "axios";
import React from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../Housing/Housing.css" ; 

const Housing = ({housing , setHousing}) => {
    const toastNotification = (message, type) => {
        toast(message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          type: type,
        });
    };

    const token = localStorage.getItem('token');
    const headers = React.useMemo(() => {
      const headersObj = {
        'Content-Type': 'application/json',
      };
      if (token) {
        headersObj['Authorization'] = `Token ${token}`;
      }
      return headersObj;
    }, [token]);
  

    const handleHousingRegister = () => {
        axios
          .post('https://icpc-official-website.onrender.com/api/housing-registration/', {}, { headers: headers })
          .then((response) => {
            setHousing({
              ...housing,
              is_registered: true,
            });
            toastNotification(response.data.Success, 'success');
          })
          .catch((err) => {
            if (err.response.status === 401) { 
              toastNotification("You are not Loggedin, please login and try again", 'error');
            } else {
              toastNotification(err.response.data.Error, 'error');
            }
          });
      }

    const handleHousingCancelRegister = () => {
        axios
          .post('https://icpc-official-website.onrender.com/api/housing-unregistration/', {}, { headers: headers })
          .then((response) => {
            setHousing({
              ...housing,
              is_registered: false,
            });
            toastNotification(response.data.Success, 'info');
          })
          .catch((err) => {
            toastNotification(err.response.data.Error, 'error');
          });
      }

    return (
      <div className="service">
        <div className="service__content">
          <h1 className="service__title">{housing.title}</h1>
          <p className="service__price"> <span> Price: </span> {housing.price}</p>
          <p className="service__description">{housing.description}</p>
          {
              Housing.is_open ? (
                      Housing.is_registered ? (
                          <button className="service-button" onClick={handleHousingCancelRegister}> Cancel </button>
                      ) :(
                          <button className="service-button" onClick={handleHousingRegister}>YES I'M IN </button>
                      )
              ) : (
                  <div className="service-closed">Closed 🔒</div>
              )
          }
        </div>
      </div>
    );
};

export default Housing ; 
