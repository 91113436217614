import React, { useState } from 'react';
import './Navbar.css';

import ListIcon from './ListIcon';
import Logo from './Logo' ;
import PageLinks from './PageLinks';
import ProfileTap from './ProfileTap';
import LoginButton from './LoginButton';

const NavBar = ({ user, setUser }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const token = localStorage.getItem('token');

  return (
    <nav className="navbar">
      <ListIcon isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen}/>
      <Logo/>
      <PageLinks isNavOpen={isNavOpen}/>
      <div className="login">
        {token && user ? <ProfileTap user={user} setUser={setUser}/> : <LoginButton/>}
      </div>
    </nav>
  );
};

export default NavBar;
