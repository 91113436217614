import React, { useState, useEffect } from 'react';
import { Dropdown } from '@mui/base/Dropdown';
import { MenuButton } from '@mui/base/MenuButton';
import { Menu } from '@mui/base/Menu';
import { MenuItem } from '@mui/base/MenuItem';
import { toast } from 'react-toastify';
import './WhatsappGroups.css';

const WhatsappGroups = ({ title, items }) => {
  const [isMobile, setIsMobile] = useState(true);

  const notify = (type, message) => {
    toast[type](message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const isMobile = /Android|Mobi/i.test(userAgent);
    setIsMobile(isMobile);
  }, []);

  function handleClick() {
    if (!isMobile) notify('info', 'Please open this page on your mobile phone to join the groups');
    else if (!items.length) notify('info', 'We will add the WhatsApp groups soon, stay tuned');
  }

  useEffect(() => {
    const links = document.querySelectorAll('.whatssapp-groups-menu-item');
    links.forEach((link) => {
      link.addEventListener('touchstart', function () {
        window.open(this.href, '_blank');
      });
    });
  }, []);

  return (
    <Dropdown>
      <MenuButton variant="contained" className="whatssapp-groups-btn" onClick={handleClick}>
        <p>{title}</p>
      </MenuButton>
      {isMobile && items.length > 0 && (
        <Menu className="whatssapp-groups-menu">
          {items.map((item, index) => {
            return (
              <MenuItem
                key={index}
                className="whatssapp-groups-menu-item"
                onClick={() => window.open(item.link, '_blank')}
              >
                {item.name}
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </Dropdown>
  );
};

export default WhatsappGroups;
