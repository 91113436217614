import { useNavigate } from "react-router-dom";
import logo from '../../assets/SCULogo.png';

const Logo = ()=>{
    const navigate = useNavigate() ; 
    return(
        <div className="logo" onClick={() => navigate('/')}>
            <img src={logo} alt="SCU Logo" />
        </div>
    );
}
export default Logo ; 