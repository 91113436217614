import Popup from "reactjs-popup";
import "../TrainingCard/TrainingCard.css" ;

const TrainingCard = ({ levelData, handleJoin, handleLeave , isJoinTrainingButtonLoading , isCancelTrainingButtonLoading}) => {
    const {
        id,
        title,
        description,
        is_open,
        prerequisite,
        content,
        start_date,
        is_registered,
    } = levelData;


    const cardBackground = {
        1: "rgba(74, 129, 194, 1)",
        2: "rgba(248, 182, 46, 1)",
        3: "rgba(175, 42, 37, 1)",
    }[id];

    // we will take the some words from the description and show it in the card
    const descriptionWords = description.split(" ");
    const descriptionWordsLength = descriptionWords.length;
    let descriptionWordsToShow = "";
    if (descriptionWordsLength > 20) {
        descriptionWordsToShow = descriptionWords.slice(0, 20).join(" ") + "...";
    } else {
        descriptionWordsToShow = description;
    }

    return (
        <section className="training__card" key={id}>
            <h2 className="training__card-title">{title}</h2>
            <h3 className="training__card-description">{descriptionWordsToShow}</h3>
            {is_open ? (
                <Popup
                    trigger={<button className="training__card-button">Learn More</button>}
                    modal
                    contentStyle={{ 
                        background: cardBackground,
                        borderRadius: "50px",
                        
                     }}
                >
                    {close => (
                        <div className="training__card-popup">
                            <div className="card-modal">
                                <button className="card-modal-close" onClick={close}>
                                    &times;
                                </button>
                                <div className="card-modal-header">{title}</div>
                                <div className="card-modal-description">{description}</div>
                                <div className="card-modal-prerequisite">
                                    <span className="prereq">Prerequisite:</span> {prerequisite}
                                </div>
                                <div className="card-modal-content">
                                    <span className="cont">Content:</span> {content}
                                </div>
                                <div className="card-modal-start">
                                    <span className="start_date">Start Date:</span> {start_date}
                                </div>
                                {!is_registered ? (
                                    <button className="card-modal-jointraining" onClick={handleJoin} disabled={isJoinTrainingButtonLoading}>
                                        {isJoinTrainingButtonLoading ? "Joining..." : "Join Training"}
                                    </button>
                                ) : (
                                    <button className="card-modal-canceltraining" onClick={handleLeave} disabled={isCancelTrainingButtonLoading}>
                                        {isCancelTrainingButtonLoading ? "Leaving..." : "Cancel Training"}
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                </Popup>
            ) : (
                <div className="training__card-closed">Closed 🔒</div>
            )}
        </section>
    );
}

export default TrainingCard; 
