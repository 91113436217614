import axios from "axios";
import {useMemo} from "react";
import Popup from "reactjs-popup";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './EventList.css';


const EventList = ({setIsLoading , setEvents , events})=>{
    const token = localStorage.getItem("token");

    const backgroundColors = [
        "rgba(74, 129, 194, 1)",
        "rgba(248, 182, 46, 1)",
        "rgba(175, 42, 37, 1)"
    ];

    const notify = (message, success = true) => {
        toast[success ? 'success' : 'error'](message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }


    const headers = useMemo(() => {
        const headersObj = {
            "Content-Type": "application/json",
        };

        if (token) {
            headersObj["Authorization"] = `Token ${token}`;
        }

        return headersObj;
    }, [token]);


    const handlejoin = async (id) => {
        try {
            const response = await axios.post("https://icpc-official-website.onrender.com/api/event-registraion/", {
                id: id,
            }, {
                headers: headers,
            });

            notify(response.data.response, true);

            setEvents(events.map((event) => {
                if (event.id === id) {
                    event.is_registered = true;
                }
                return event;
            }));

        } catch (err) {

            if (err.response.data.Error || err.response.data.detail) {
                const errorMessage = err.response.data.Error || err.response.data.detail;
                notify(errorMessage, false);
            }
        }
    }

    const handleleave = async (id) => {
        try {
            const response = await axios.post("https://icpc-official-website.onrender.com/api/event-unregistraion/", {
                id: id,
            }, {
                headers: headers,
            });

            notify(response.data.response, true);

            setEvents(events.map((event) => {
                if (event.id === id) {
                    event.is_registered = false;
                }
                return event;
            }));

        } catch (err) {
            
            notify(err.response.data.detail, false);
        }
    }


    return(
        <>
            {
                events.map((event,index)=>(
                    <div className="event" key={event.id}>
                        <h2 className="event__title">{event.title}</h2>
                        <h3 className="event__description">{event.definition}</h3>
                        <img src={"https://icpc-official-website.onrender.com"+event.image_link} alt="event" className="event__img" />
                        {
                            event.is_open ? (
                                <Popup
                                trigger={
                                    <button className="event__button">Learn More</button>
                                }
                                modal
                                contentStyle={{
                                    background: backgroundColors[index % backgroundColors.length],
                                    borderRadius: "30px",
                                }}
                            >
                                {close => (
                                    <div className="event-modal">
                                        <button className="event-modal-close" onClick={close}>&times;</button>
                                        <div className="event-modal-data">
                                        <div className="event-modal-header"> {event.title} </div>
                                        <div className="event-modal-content"> {event.content} </div>
                                        <div className="event-modal-description"> {event.description} </div>
                                        <div className="event-modal-requirments"> <span className="pre-def">Requirements : </span> {event.requirements} </div>
                                        <div className="event-modal-location"> <span className="pre-def">Location : </span> {event.location} </div>
                                        <div className="event-modal-start"> <span className="pre-def">Start Date : </span> {event.start_date} </div>
                                        <div className="event-modal-diedline"> <span className="pre-def">Deadline : </span> {event.deadline} </div>
                                        {!event.is_registered ? (
                                            <button className="event-modal-join" onClick={() => handlejoin(event.id)}>Join Event</button>
                                        ) : (
                                            <button className="event-modal-cancel" onClick={() => handleleave(event.id)}>Cancel Event</button>
                                        )}
                                        </div>
                                        <div className="event-modal-img-container">
                                        <img src={"https://icpc-official-website.onrender.com" + event.image_link} alt="event" className="event-modal-img" />
                                        </div>
                                </div>
                                )}
                            </Popup>
                            ):(
                                <div className="event__closed">Closed 🔒</div>
                            )
                        }
                    </div>
                    )
                )
            }
        </>
    );
}

export default EventList ; 