import "../MessageEventCard/MessageEventCard.css" ; 

const MessageEventCard = ({children})=>{
    return(
        <section className="events__content">
            <h1 className="events__title">Current Events</h1>
            <p className="training-description">{children}</p>
        </section>
    );
}

export default MessageEventCard ; 