import { NavLink } from "react-router-dom";

const PageLinks = ({isNavOpen})=>{
    return (
        <>
            <div className={`navbar-dropdown-menu ${isNavOpen ? 'open' : ''}`}>
                <NavLink to="/" className={(navData) => (navData.isActive ? 'navbar-link active' : 'navbar-link')}>
                Home
                </NavLink>
                <NavLink to="/training" className={(navData) => (navData.isActive ? 'navbar-link active' : 'navbar-link')}>
                Training
                </NavLink>
                <NavLink to="/ecpc" className={(navData) => (navData.isActive ? 'navbar-link active' : 'navbar-link')}>
                ECPC
                </NavLink>
                <NavLink to="/events" className={(navData) => (navData.isActive ? 'navbar-link active' : 'navbar-link')}>
                Events
                </NavLink>
                <NavLink to="/achievements" className={(navData) => (navData.isActive ? 'navbar-link active' : 'navbar-link')}>
                Achievements
                </NavLink>
            </div>
            <div className="links">
                <NavLink to="/" className={(navData) => (navData.isActive ? 'navbar-link active' : 'navbar-link')}>
                Home
                </NavLink>
                <NavLink to="/training" className={(navData) => (navData.isActive ? 'navbar-link active' : 'navbar-link')}>
                Training
                </NavLink>
                <NavLink to="/ecpc" className={(navData) => (navData.isActive ? 'navbar-link active' : 'navbar-link')}>
                ECPC
                </NavLink>
                <NavLink to="/events" className={(navData) => (navData.isActive ? 'navbar-link active' : 'navbar-link')}>
                Events
                </NavLink>
                <NavLink to="/achievements" className={(navData) => (navData.isActive ? 'navbar-link active' : 'navbar-link')}>
                Achievements
                </NavLink>
            </div>
        </>
    );
}
export default PageLinks ; 