import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import "../PopupRegister/PopupRegister.css" ; 

const PopupRegister = ({ECPC , setECPC}) => {
    const [Team, setTeam] = useState({
        team_name: '',
        second_participant: '',
        third_participant: '',
    });

    const token = localStorage.getItem('token');
    const headers = React.useMemo(() => {
      const headersObj = {
        'Content-Type': 'application/json',
      };
      if (token) {
        headersObj['Authorization'] = `Token ${token}`;
      }
      return headersObj;
    }, [token]);
  

    const toastNotification = (message, type) => {
        toast(message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          type: type,
        });
    };
    

    const handleChange = (e) => {
        const { name, value } = e.target;
        setTeam((prevTeam) => {
          return {
            ...prevTeam,
            [name]: value,
          };
        });
      };
    
      const handleRegister = (e) => {
        e.preventDefault();
        
        axios
          .post('https://icpc-official-website.onrender.com/api/ecpcq-registration/', Team, { headers: headers })
          .then((response) => {
            toastNotification(response.data.Success, 'success');
            setECPC({
              ...ECPC,
              is_registered: true,
            });
            
          })
          .catch((err) => {
            if (err.response.status === 401) {
              toastNotification("You are not Loggedin, please login and try again", 'error');
            }
            else {
              const ErrorsArray = err.response.data.Error;
              for (const error of ErrorsArray) {
                toastNotification(error, 'error');
              }
            }
            
          });
      };
    
      const handleCancelRegister = () => {
        axios
          .post('https://icpc-official-website.onrender.com/api/ecpcq-unregistration/', {}, { headers: headers })
          .then((response) => {
            setECPC({
              ...ECPC,
              is_registered: false,
            });
            toastNotification(response.data.Success, 'info');
          })
          .catch((err) => {
            toastNotification(err.response.data.Error, 'error');
          });
      };
    
    

    return (
        <Popup
          trigger={<button className="ECPC-Register-btn"> Register Now !</button>}
          modal
          contentStyle={{
            background: '#fff', 
            borderRadius: '51px',
          }}
        >
          {(close) => (
            <div className="ECPC-modal-container">
              <div className="ECPC-modal">
                <button className="ECPC-close" onClick={close}>
                  &times;
                </button>
                {ECPC.is_registered ? (
                  <div className="ECPC-ECPCRegistration__form">
                    <h1 className="ECPC-ECPCRegistration__title">You have registered</h1>
                    <button className="ECPC-cancel-button" onClick={handleCancelRegister}>
                      Cancel Register
                    </button>
                  </div>
                ) : (
                  <form onSubmit={handleRegister}>
                    <div className="ECPC-ECPCRegistration__form">
                      <h1 className="ECPC-ECPCRegistration__title">{ECPC.title} - Registeration</h1>
                      <input
                        type="text"
                        name="team_name"
                        placeholder="Team Name"
                        value={Team.team_name}
                        onChange={handleChange}
                        className="ECPC-ECPCRegistration__input"
                        required
                      />
                      <input
                        type="text"
                        name="second_participant"
                        placeholder="Second Participant"
                        value={Team.second_participant}
                        onChange={handleChange}
                        className="ECPC-ECPCRegistration__input"
                        required
                      />
                      <input
                        type="text"
                        name="third_participant"
                        placeholder="Third Participant"
                        value={Team.third_participant}
                        onChange={handleChange}
                        className="ECPC-ECPCRegistration__input"
                        required
                      />
                      <button
                        type="submit"
                        className="ECPC-button ECPC-ECPCRegistration__register-button"
                      >
                        Register
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          )}
        </Popup>
    );
};

export default PopupRegister ; 