import axios from "axios";
import React from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../Transportation/Transportation.css" ; 

const Transportation = ({transportation , setTransportation}) => {
    const token = localStorage.getItem('token');
    const headers = React.useMemo(() => {
      const headersObj = {
        'Content-Type': 'application/json',
      };
      if (token) {
        headersObj['Authorization'] = `Token ${token}`;
      }
      return headersObj;
    }, [token]);
  

    const toastNotification = (message, type) => {
        toast(message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          type: type,
        });
    };

    const handleTransportationRegister = () => {
        axios
          .post('https://icpc-official-website.onrender.com/api/transportation-registration/', {}, { headers: headers })
          .then((response) => {
            setTransportation({
              ...transportation,
              is_registered: true,
            });
            toastNotification(response.data.Success, 'success');
          })
          .catch((err) => {
            if (err.response.status === 401) {
              toastNotification("You are not Loggedin, please login and try again", 'error');
            } else {
              toastNotification(err.response.data.Error, 'error');
            }
          });
    }

    const handleTransportationCancelRegister = () => {
        axios
          .post('https://icpc-official-website.onrender.com/api/transportation-unregistration/', {}, { headers: headers })
          .then((response) => {
            setTransportation({
              ...transportation,
              is_registered: false,
            });
            toastNotification(response.data.Success, 'info');
          })
          .catch((err) => {
            toastNotification(err.response.data.Error, 'error');
          });
    }


    return (
      <div className="service">
        <div className="service__content">
          <h1 className="service__title"> {transportation.title} </h1>
          <p className="service__price"> <span> Price: </span> {transportation.price} </p>
          <p className="service__description"> {transportation.description} </p>
          {
              Transportation.is_open ? (
                Transportation.is_registered ? (
                          <button className="service-button" onClick={handleTransportationCancelRegister}> Cancel </button>
                      ) : (
                          <button className="service-button" onClick={handleTransportationRegister}>YES I'M IN </button>
                      )
              ) : (
                  <div className="service-closed">Closed 🔒</div>
              )
          }
        </div>
      </div>
    );
};
export default Transportation ; 