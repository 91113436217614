import logo from '../../../assets/SCULogo.png';
import ECPC from '../../../assets/ECPC22.jpg';
import "./LoginIntro.css" ; 

const LoginIntro = ({children})=>{
    return (
        <>
            <img src={ECPC} alt="ECPC" className="login-ecpc-image" />
            <div className="login-container">
              <h5 className = 'login-title'> Join our community now and be part of us!</h5>
              <p className = 'login-description'>Registration is important if you want to stay updated about the community.</p>

              <div className="logo">
                <img src={logo} alt="SCU Logo" />
              </div>

              {children}
            </div>

        </>
    );
}

export default LoginIntro ; 