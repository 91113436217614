import React from "react";
import "./Intro.css";
import ECPC22 from "../../../assets/ECPC22.jpg";
const Intro = () => {

    return(
        <section className="scu-website">
        <div className="scu-website__content">
          <h1 className="scu-website__title">ICPC SCU OFFICIAL WEBSITE</h1>
          <p className="scu-website__description">
            Here you can learn about our community and be a part of it! If you
            are looking for a community that provides you with special training
            and also fun events and contests, then <span> we are the one! </span>
          </p>
        </div>
        <img src={ECPC22} alt="ECPC22" className="scu-website__image" />
      </section>
    )
};

export default Intro;