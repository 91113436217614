const ListIcon = ({isNavOpen , setIsNavOpen})=>{
    const toggleNavbar = () => {
        setIsNavOpen(!isNavOpen);
    
        const navbarDropdownMenu = document.querySelector('.navbar-dropdown-menu');
        const navbarLinks = document.querySelectorAll('.navbar-link');
        navbarLinks.forEach((link) => {
          link.addEventListener('click', () => {
            setIsNavOpen(false);
            navbarDropdownMenu.classList.remove('open');
          });
        });
    
        const navbarToggle = document.querySelector('.hamburger');
        window.addEventListener('click', (e) => {
          if (!navbarDropdownMenu.contains(e.target) && !navbarToggle.contains(e.target)) {
            setIsNavOpen(false);
            navbarDropdownMenu.classList.remove('open');
          }
        });
    
    };
        
    return (
        <div className={`hamburger ${isNavOpen ? 'open' : ''}`} onClick={toggleNavbar}>
            <div className={`top-bun ${isNavOpen ? 'open' : ''}`}></div>
            <div className={`meat ${isNavOpen ? 'open' : ''}`}></div>
            <div className={`bottom-bun ${isNavOpen ? 'open' : ''}`}></div>
        </div>
    );
}

export default ListIcon ; 