import React from 'react';
import WhatsappGroups from '../WhatsappGroups/WhatsappGroups';
import verified from "../../../assets/verified.gif";
import unverified from "../../../assets/unverified.png";

const UserProfile = ({user, isVerified}) => {
    return (
      <div className="profile-data">
        <div className="user-photo">
          <img src={user.picture} alt="Profile Pic" className="profile-pic"/>
          <img src={isVerified ? verified : unverified} alt="Verified" className="verified"/>
        </div>
        <h3 className="username">{user.name}</h3>
        <p className="email-title">email : <span className="user-email">{user.email}</span> </p>
        <WhatsappGroups title="whatsapp groups" items={user.whatsapp_groups_data} />
      </div>
    );
  };

export default UserProfile;