import React from "react";
import Intro from "./Intro";
import Contacts from "./Contacts";
import Links from "./Links";
import CopyRights from "./CopyRights";
import "./Footer.css" ; 

const FooterContainer = () => {
    return (
        <div className="footer">
            <div className="upper">
                <Intro/>
                <Contacts/>
                <Links/>
            </div>
            <div className="line"></div>
            <CopyRights />
        </div>
    );
}

export default FooterContainer;