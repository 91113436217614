import Terms from "../Terms/Terms" ; 

const CopyRights = ()=>{
    return(
        <div className="lower">
            <Terms>
                <div className="lower-link">
                    Privacy Policy
                </div>
            </Terms>
            <Terms>
                <p className="lower-link">
                    © 2023 ICPC SCU. All rights reserved.
                </p>
            </Terms>
            <Terms>
                <div className="lower-link">
                    Terms and Conditions
                </div>
            </Terms>
        </div>
    );
}

export default CopyRights ; 