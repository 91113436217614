import React from 'react';
import Popup from 'reactjs-popup';
import './Terms.css';

const Terms = ({children}) => {
    return (
        <Popup
            trigger={
                <div className="terms-trigger">
                    {children}
                </div>
            }
            modal
            contentStyle={{
                background: "#f2f2f2",
                width: "80%",
                height: "80%",
            }}
            >
            {(close) => (
                <div className="custom-popup">
                <button className="custom-close" onClick={close}>
                    &times;
                </button>
                <div className="custom-header">Privacy Policy</div>
                <div className="custom-content">
                    <p>Welcome to our ICPC Community website! We care about your privacy, and we want to make sure you know how we handle your information.</p>
                    <p><strong>Information We Collect:</strong> When you register for trainings, events, or competitions, we may collect some personal information like your name, email address, and school affiliation. We promise to keep this information safe and only use it for community-related purposes.</p>
                    <p><strong>Sharing Information:</strong> We won't share your personal information with anyone outside our ICPC community unless we have a really good reason to, like if it's required by law or for security reasons.</p>
                    <p><strong>Security:</strong> We do our best to protect your information from unauthorized access or data breaches. We use encryption and other security measures to keep everything safe.</p>
                    <p><strong>Your Choices:</strong> You can always review and update your personal information in your account settings. If you ever want us to delete your information, just let us know, and we'll do our best to accommodate your request.</p>
                    <p><strong>Changes to This Policy:</strong> If we ever need to make changes to this privacy policy, we'll let you know by posting an update on our website.</p>
                </div>
                <br></br>
                <hr></hr>
                <br></br>
                <div className="custom-header">Terms and Conditions</div>
                <div className="custom-content">
                    <p>By using our ICPC Community website, you agree to the following terms and conditions:</p>
                    <p><strong>Registration:</strong> To access certain features like trainings, events, and competitions, you may need to create an account. Please provide accurate and complete information when registering.</p>
                    <p><strong>Respectful Conduct:</strong> This is a community website, so let's treat each other with respect. No harassment, hate speech, or inappropriate behavior will be tolerated.</p>
                    <p><strong>Content Usage:</strong> You can use the content on our website for personal and educational purposes related to the ICPC community. However, you can't use it for commercial purposes without our permission.</p>
                    <p><strong>Account Security:</strong> You're responsible for keeping your account credentials safe. Don't share your login details with anyone else.</p>
                    <p><strong>Termination:</strong> We can suspend or terminate your account if you violate these terms or if it's necessary for security reasons.</p>
                </div>
                <br></br>
                <hr></hr>
                <br></br>
                <div className="custom-header">Copyright Notice</div>
                <div className="custom-content">
                    <p>All the content on our ICPC Community website, including text, images, and other materials, is protected by copyright law. This means it's our intellectual property, and you can't use it without our permission. If you'd like to use any of our content, please contact us for approval.</p>
                    <ul className="developers-list">
                        <li>The website was designed and implemented by Omar Alaraby, Reem Rashad, and Efraim Nabil, under the supervision of our instructor, Wael Ahmed.</li>
                        <li>All intellectual property rights, including copyright, in the content of this website belong to the <b>DEV Team Committee</b> in ICPC SCU community.</li>
                        <li>Any unauthorized use or reproduction of the content on this website is strictly prohibited and may result in legal action.</li>
                    </ul>
                </div>
                <button className="custom-button" onClick={close}>Got it</button>
                </div>
            )}
            </Popup>
    );
}

export default Terms;
