import React, { useEffect, useMemo, useState} from "react";
import "../EventsContainer/EventsContainer.css";
import EventList from "../EventList/EventList"
import MessageEventCard from "../MessageEventCard/MessageEventCard";
import axios from "axios";
import Loader from "../../Loader/Loader";

const EventsContainer = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [events, setEvents] = useState([]);
    const token = localStorage.getItem("token");


    const headers = useMemo(() => {
        const headersObj = {
            "Content-Type": "application/json",
        };

        if (token) {
            headersObj["Authorization"] = `Token ${token}`;
        }

        return headersObj;
    }, [token]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get("https://icpc-official-website.onrender.com/api/events/", {
                    headers: headers,
                });
                setEvents(response.data);
                setIsLoading(false);
            } catch (err) {
                setIsLoading(false);
            }
        }

        fetchData();
    }, [headers, setEvents, setIsLoading]);



    return (
        <div className="events">
            {
                isLoading ? (
                    <Loader />
                ):
                (
                    !events.length ? (
                        <div className="events__container">
                                <MessageEventCard>There are no events currently. Please check back later.</MessageEventCard>
                        </div>
                    ) : (
                        <div className="events__container">
                                <MessageEventCard>Check the events coming soon to participate in it and to have fun with so many different activites. </MessageEventCard>
                            <section className="events__list">
                                <EventList setEvents={setEvents} events={events} setIsLoading={setIsLoading} />
                            </section>
                        </div>
                    )
                )
            }
        </div>
    );
}

export default EventsContainer;