import { Dropdown } from '@mui/base/Dropdown';
import { MenuButton } from '@mui/base/MenuButton';
import { Menu } from '@mui/base/Menu';
import { MenuItem } from '@mui/base/MenuItem';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const ProfileTap = ({user , setUser})=>{
    
    const token = localStorage.getItem('token');
    const navigate = useNavigate() ; 

    function handleLogout() {
        setUser(null);
        navigate('/');
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        };
        axios
          .post('https://icpc-official-website.onrender.com/api/logout/', {}, { headers })
          .then((res) => {
            // remove everything from local storage
            localStorage.clear();
          })
          .catch((err) => {
            console.error(err);
          });
    }
    


    return (
        <Dropdown>
            <MenuButton variant="contained" className="user">
            <img src={user.picture} alt="User" />
            <p>{user.username}</p>
            </MenuButton>
            <Menu className="menu">
            <MenuItem
                className="menu-item"
                onClick={() => {
                navigate('/profile');
                }}
            >
                Profile
            </MenuItem>
            <MenuItem
                className="menu-item"
                onClick={() => {
                handleLogout();
                }}
            >
                Logout
            </MenuItem>
            </Menu>
        </Dropdown>
    ) ; 
}

export default ProfileTap ; 