import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ProtectRouter = ({children, childrenName}) => {
    const navigate = useNavigate();
    const isAuthenticated = localStorage.getItem('token');
    const isEmailExist = localStorage.getItem('email');

    useEffect(function(){
        switch(childrenName){
            case 'Profile':
                if(!isAuthenticated) navigate('/login');
                break;
            case 'Login':
                if(isAuthenticated) navigate('/not-found');
                break;
            case 'Register':
                if(!isEmailExist || isAuthenticated) navigate('/not-found');
                break;
            default:
                break;
        }
    } , [isAuthenticated, navigate, isEmailExist, childrenName]);

    switch(childrenName){
        case 'Profile':
            return isAuthenticated && children;
        case 'Login':
            return !isAuthenticated && children;
        case 'Register':
            return !isAuthenticated && isEmailExist && children;
        default:
            return children;
    }
}

export default ProtectRouter ; 