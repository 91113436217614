import React from "react";
import Intro from "../../components/Home/Intro/Intro";
import GrowUp from "../../components/Home/GrowUp/GrowUp";
import News from "../../components/Home/News/News";
import "./Home.css";
const Home = () => {
    return (
        <div className="home">
            <Intro />
            <GrowUp />
            <News />
        </div>
    );
};

export default Home;