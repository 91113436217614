import React, { useState, useEffect , useMemo } from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import Loader from "../../components/Loader/Loader" ; 
import "./Profile.css";
import VerificationData from "../../components/Profile/VerficationData/VerficationData";
import UserProfile from "../../components/Profile/UserProfile/UserProfile";
import UserData from "../../components/Profile/UserData/UserData";

const Profile = ({ user , setUser }) => {
  const id = localStorage.getItem("id");
  const token = localStorage.getItem("token");
  const headers = useMemo(() => {
    const headersObj = {
            "Content-Type": "application/json",
        };
        if (token) {
            headersObj["Authorization"] = `Token ${token}`;
        }

        return headersObj;
    }, [token]);

  const [userData, setUserData] = useState({});
  const [formData, setFormData] = useState({});
  const [verificationData, setVerificationData] = useState({});
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const notify = (type , message) => {
    toast[type](message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
  }

  useEffect(() => {
    const fetchData = async () => {
      const datafromlocal = localStorage.getItem("profile-data");

      if (datafromlocal) {
        const parsedData = JSON.parse(datafromlocal);
        setUserData(parsedData);
        setFormData({
          username: parsedData.username,
          university: parsedData.university,
          faculty: parsedData.faculty,
          grade: parsedData.grade,
          whatsapp_number: parsedData.whatsapp_number,
          codeforces_handle: parsedData.codeforces_handle,
          date_of_birth: parsedData.date_of_birth,
        });
        setVerificationData({
          icpc_global: parsedData.icpc_global,
          national_ID: parsedData.national_ID,
          city: parsedData.city,
          year_of_enrollment: parsedData.year_of_enrollment,
          quaternary_name: parsedData.quaternary_name,
        });
        setIsVerified(parsedData.is_verified);
        setIsLoading(false);
      } else {
        try {
          const response = await axios.get(
            `https://icpc-official-website.onrender.com/api/users/${id}/`,
            { headers: headers }
          );
          const userData = response.data;
          setUserData(userData);
          setFormData({
            username: userData.username,
            university: userData.university,
            faculty: userData.faculty,
            grade: userData.grade,
            whatsapp_number: userData.whatsapp_number,
            codeforces_handle: userData.codeforces_handle,
            date_of_birth: userData.date_of_birth,
          });
          setIsVerified(userData.is_verified);
          localStorage.setItem("profile-data", JSON.stringify(userData));
          setIsLoading(false);
        } catch (error) {
          notify("error" , "Error fetching data. Please try again.");
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [id, headers]);

  return (
    <div className="profile">
        {
            isLoading ? 
              <Loader />
             : (
                <>
                    <section className="main-data">
                        <UserProfile 
                          user={user} 
                          isVerified={isVerified} 
                        />
                        <UserData
                            formData={formData}
                            setFormData={setFormData}
                            userData={userData}
                            setUserData={setUserData}
                            user={user}
                            setUser={setUser}
                        />
                    </section>
                    <section className="verifiy-section">
                        <VerificationData 
                          userData={userData} 
                          verificationData={verificationData} 
                          setVerificationData={setVerificationData} 
                          isVerified={isVerified} 
                          setIsVerified={setIsVerified} 
                        />
                    </section>
                </>
            )
        }
    </div>
  );
};

export default Profile;
