import React from 'react';

const VerifiyInput = ({fieldName, label, userData, verificationData, setVerificationData, isVerified}) => {

    return (
      <div className="form-group">
        <label className="form-label">{label}</label>
        <div className="form-input">
          <input
            type="text"
            onChange={(e) =>
              setVerificationData({ ...verificationData, [fieldName]: e.target.value })
            }
            placeholder={userData[fieldName]}
            disabled={isVerified}
            required
          />
        </div>
      </div>
    );
};

export default VerifiyInput;