import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cards from "../Cards/Cards";
import "../TrainingContainer/TrainingContainer.css" ;
import Loader from "../../Loader/Loader";

const TrainingContainer = () => {
    const [levelZero, setLevelZero] = useState({});
    const [levelOne, setLevelOne] = useState({});
    const [levelTwo, setLevelTwo] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const token = localStorage.getItem("token");

    const notify = (type , message) => {
      toast[type](message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    };

    const headers = useMemo(() => {
        const headersObj = {
            "Content-Type": "application/json",
        };
        if (token) {
            headersObj["Authorization"] = `Token ${token}`;
        }

        return headersObj;
    }, [token]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [responseZero, responseOne, responseTwo] = await Promise.all([
                    axios.get("https://icpc-official-website.onrender.com/api/lvlzero/", {
                        headers: headers,
                    }),
                    axios.get("https://icpc-official-website.onrender.com/api/lvlone/", {
                        headers: headers,
                    }),
                    axios.get("https://icpc-official-website.onrender.com/api/lvltwo/", {
                        headers: headers,
                    })
                ]);

                setLevelZero(responseZero.data[0]);
                setLevelOne(responseOne.data[0]);
                setLevelTwo(responseTwo.data[0]);
            } catch (err) {
                notify("error", "Something went wrong.");
            } finally {
                setIsLoading(false); // Set isLoading to false whether there was an error or not
            }
        };

        fetchData();
    }, [headers]);
    

    
    
    const isundefined = (level) => {
        if (typeof level === "object") {
            if (Object.keys(level).length === 0) {
                return true;
            }
            return false;
        }
        if (typeof level === "undefined") {
            return true;
        }
        return false;
    }


    return (
        <div className="training">
            {
                isLoading ? (
                    <Loader/>
                ) : (
                    isundefined(levelZero) && isundefined(levelOne) && isundefined(levelTwo) ? (
                        <section className="training__content">
                            <h1 className="training__title">No Training Available</h1>
                            <p className="training__description">
                                There is no training available right now. Please check again later.
                            </p>
                        </section>
                    ) : (
                    <section className="training__content">
                        <h1 className="training__title">Choose your level</h1>
                        <p className="training__description">
                            We provide training for all levels of students. We have 3 levels of training, 
                            which are <span> Level 0, Level 1, and Level 2. </span> 
                            You can choose the level you want to join according to your programming experience.
                        </p>
                        <Cards
                            levelZero={levelZero}
                            levelOne={levelOne}
                            levelTwo={levelTwo}
                            setLevelZero={setLevelZero}
                            setLevelOne={setLevelZero}
                            setLevelTwo={setLevelTwo}
                        />
                    </section>
                    )
                )
            }
        </div>
    );
}

export default TrainingContainer;