import { useMemo, useState } from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TrainingCard from "../TrainingCard/TrainingCard";
import "../Cards/Cards.css" ; 
const Cards = ({ levelZero, levelOne, levelTwo , setLevelZero, setLevelOne, setLevelTwo }) => {
    const [isJoinTrainingButtonLoading, setIsJoinTrainingButtonLoading] = useState(false);
    const [isCancelTrainingButtonLoading, setIsCancelTrainingButtonLoading] = useState(false);

    const token = localStorage.getItem("token");

    const notify = (type , message) => {
      toast[type](message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    };

    const headers = useMemo(() => {
        const headersObj = {
            "Content-Type": "application/json",
        };
        if (token) {
            headersObj["Authorization"] = `Token ${token}`;
        }

        return headersObj;
    }, [token]);


    const handleJoin = async (level) => {
        setIsJoinTrainingButtonLoading(true); // Set loading state to true

        try {
            // Make the async axios call
            const levelPrefMap = {
                0: "lvlzero",
                1: "lvlone",
                2: "lvltwo",
            };

            const levelPref = levelPrefMap[level];

            const res = await axios.post(`https://icpc-official-website.onrender.com/api/add-user-${levelPref}/`, {}, {
                headers: headers,
            });

            notify("success", res.data.response);

            // Update the appropriate level state based on 'level'
            const levelStateSetter = {
                0: setLevelZero,
                1: setLevelOne,
                2: setLevelTwo,
            }[level];

            if (levelStateSetter) {
                levelStateSetter((prevLevelState) => ({
                    ...prevLevelState,
                    is_registered: true,
                }));
            }
        } catch (err) {
            if (err.response.status === 400) {
                const errorMessage = err.response.data.Error;
                notify("error", errorMessage);
            }
            else if (err.response.status === 401) {
                notify("error", "You have to login to join the training.");
            }
            else if (err.response.status === 429) {
                notify("error", err.response.data.Error);
            }
            else if (err.response.status === 500) {
                notify("error", "Something went wrong.");
            }
        } finally {
            setIsJoinTrainingButtonLoading(false); // Reset loading state to false
        }
    }

    const handleLeave = async (level) => {
        setIsCancelTrainingButtonLoading(true); // Set loading state to true

        try {
            // Make the async axios call
            const levelPrefMap = {
                0: "lvlzero",
                1: "lvlone",
                2: "lvltwo",
            };

            const levelPref = levelPrefMap[level];

            const res = await axios.post(`https://icpc-official-website.onrender.com/api/remove-user-${levelPref}/`, {}, {
                headers: headers,
            });

            notify("success", res.data.response);
            // Update the appropriate level state based on 'level'
            const levelStateSetter = {
                0: setLevelZero,
                1: setLevelOne,
                2: setLevelTwo,
            }[level];

            if (levelStateSetter) {
                levelStateSetter((prevLevelState) => ({
                    ...prevLevelState,
                    is_registered: false,
                }));
            }
        } catch (err) {
            if (err.response.data.Error || err.response.data.detail) {
                const errorMessage = err.response.data.Error || err.response.data.detail;
                notify("error", errorMessage);
            }
        } finally {
            setIsCancelTrainingButtonLoading(false); // Reset loading state to false
        }
    }

    return (
        <div className="cards">
            {levelZero && <TrainingCard 
            levelData={levelZero} 
            handleJoin={() => handleJoin(0)} 
            handleLeave={() => handleLeave(0)} 
            isJoinTrainingButtonLoading={isJoinTrainingButtonLoading}
            isCancelTrainingButtonLoading={isCancelTrainingButtonLoading}/>}
            
            {levelOne && <TrainingCard 
            levelData={levelOne} 
            handleJoin={() => handleJoin(1)} 
            handleLeave={() => handleLeave(1)} 
            isJoinTrainingButtonLoading={isJoinTrainingButtonLoading}
            isCancelTrainingButtonLoading={isCancelTrainingButtonLoading}/>}

            {levelTwo && <TrainingCard 
            levelData={levelTwo} 
            handleJoin={() => handleJoin(2)} 
            handleLeave={() => handleLeave(2)} 
            isJoinTrainingButtonLoading={isJoinTrainingButtonLoading}
            isCancelTrainingButtonLoading={isCancelTrainingButtonLoading}/>}
        </div>
    );
}

export default Cards ; 
