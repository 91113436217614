import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import validator from "validator";
import { FormInputText , FormInputSelect, FormInputPhone, FormInputDate } from "./FormInputs";
import Buttons from "./Buttons";

const UserData = ({formData, setFormData, userData, setUserData, user, setUser}) => {
  const [editingField, setEditingField] = useState(null);
  const [isSaveButtonEnabled, setSaveButtonEnabled] = useState(false);
  const [isSaveButtonLoading, setSaveButtonIsLoading] = useState(false);
  const token = localStorage.getItem("token");
  const headers = useMemo(() => {
    const headersObj = {
            "Content-Type": "application/json",
        };
        if (token) {
            headersObj["Authorization"] = `Token ${token}`;
        }

        return headersObj;
    }, [token]);
  

  useEffect(() => {
    const data = {
      username: userData.username,
      university: userData.university,
      faculty: userData.faculty,
      grade: userData.grade,
      whatsapp_number: userData.whatsapp_number,
      codeforces_handle: userData.codeforces_handle,
      date_of_birth: userData.date_of_birth,
    };
    const hasDataChanged = Object.keys(data).some(
      (key) => data[key] !== formData[key]
    );
    setSaveButtonEnabled(hasDataChanged);
  }, [formData, userData]);

  const notify = (type , message) => {
    toast[type](message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
  }

  const validatePhone = (phone) => {
    if (validator.isMobilePhone('+' + phone)) {
      return true;
    }
    notify("error" , "Invalid phone number");
    return false;
  }

  const handleCancelClick = () => {
    setFormData({
      username: userData.username,
      university: userData.university,
      faculty: userData.faculty,
      grade: userData.grade,
      whatsapp_number: userData.whatsapp_number,
      codeforces_handle: userData.codeforces_handle,
      date_of_birth: userData.date_of_birth,
    });
    setEditingField(null);
    setSaveButtonEnabled(false);
  }; 

  const handleSaveClick = async () => {
    try {
      if (!validatePhone(formData.whatsapp_number)) {
        return;
      }
  
      setSaveButtonIsLoading(true);
  
      const res = await axios.put(`https://icpc-official-website.onrender.com/api/update-user/`, formData, {
        headers: headers,
      });
  
      setUserData(res.data.data);
      localStorage.setItem("profile-data", JSON.stringify(res.data.data));
      setFormData({
        username: res.data.data.username,
        university: res.data.data.university,
        faculty: res.data.data.faculty,
        grade: res.data.data.grade,
        whatsapp_number: res.data.data.whatsapp_number,
        codeforces_handle: res.data.data.codeforces_handle,
        date_of_birth: res.data.data.date_of_birth,
      });
      setEditingField(null);
      setSaveButtonIsLoading(false);
  
      setUser({
        ...user,
        username: res.data.data.username,
      });
  
      notify("success", "Data updated successfully");
    } catch (err) {
      if (err.response) {
        const errors = err.response.data.Error;
        Object.values(errors).forEach((value) => {
          notify("error", value);
        });
      } else {
        notify("error", "An error occurred");
      }
    } finally {
      setSaveButtonIsLoading(false);
    }
  };

    return (
      <div className="user-data">
        <FormInputText 
            fieldName="username" 
            label="Username" 
            value={formData.username} 
            isEditing={editingField === "username"} 
            formData={formData} 
            setEditingField={setEditingField} 
            setFormData={setFormData} 
        />
        <FormInputSelect 
            fieldName="university" 
            label="University" 
            value={formData.university} 
            isEditing={editingField === "university"} 
            options={["Suez Canal University", "Other"]} 
            formData={formData} 
            setEditingField={setEditingField} 
            setFormData={setFormData} 
        />
        <FormInputSelect 
            fieldName="faculty" 
            label="Faculty" 
            value={formData.faculty} 
            isEditing={editingField === "faculty"} 
            options={["Computer Science", "Engineering", "Other"]} 
            formData={formData} 
            setEditingField={setEditingField} 
            setFormData={setFormData} 
        />
        <FormInputSelect 
            fieldName="grade" 
            label="Grade" 
            value={formData.grade} 
            isEditing={editingField === "grade"} 
            options={["First", "Second", "Third", "Fourth", "Fifth", "Sixth", "Seventh", "Other"]} 
            formData={formData} 
            setEditingField={setEditingField} 
            setFormData={setFormData} 
        />
        <FormInputPhone 
            fieldName="whatsapp_number" 
            label="Whatsapp Number" 
            value={formData.whatsapp_number} 
            isEditing={editingField === "whatsapp_number"} 
            formData={formData} 
            setEditingField={setEditingField} 
            setFormData={setFormData} 
        />
        <FormInputText 
            fieldName="codeforces_handle" 
            label="Codeforces Handle" 
            value={formData.codeforces_handle} 
            isEditing={editingField === "codeforces_handle"} 
            formData={formData} 
            setEditingField={setEditingField} 
            setFormData={setFormData} 
        />
        <FormInputDate 
            fieldName="date_of_birth" 
            label="Date of Birth" 
            value={formData.date_of_birth} 
            isEditing={editingField === "date_of_birth"} 
            formData={formData} 
            setEditingField={setEditingField} 
            setFormData={setFormData} 
        />
        <Buttons 
            isSaveButtonEnabled={isSaveButtonEnabled} 
            isSaveButtonLoading={isSaveButtonLoading} 
            editingField={editingField} 
            handleSaveClick={handleSaveClick} 
            handleCancelClick={handleCancelClick} 
        />
      </div>
    );
  };

export default UserData;