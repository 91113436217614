const Buttons = ({ isSaveButtonEnabled, isSaveButtonLoading, editingField, handleSaveClick, handleCancelClick }) => {
    if (isSaveButtonEnabled) {
      return (
        <div className="button-container">
          <button onClick={handleSaveClick} className="save-button" disabled={isSaveButtonLoading}>
            {isSaveButtonLoading ? "Saving..." : "Save"}
          </button>
          <button onClick={handleCancelClick} className="cancel-button">Cancel</button>
        </div>
      );
    } else if (editingField !== null) {
      return (
        <div className="button-container">
          <button onClick={handleCancelClick} className="cancel-button">Cancel</button>
        </div>
      );
    } else {
      return null;
    }
  };

export default Buttons;