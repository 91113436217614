import React from "react";
import { FiChevronRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import ECPC21 from "../../../assets/ECPC21.png";
import pal_flag from "../../../assets/pal_flag.png";
import "./GrowUp.css";

const GrowUp = () => {

    const navigate = useNavigate();
    
    return (
        <section className="grow-up">
        <div className="grow-up__image-container">
          <div className="imgs">
              <img src={ECPC21} alt="ECPC21" className="img1" />
              <img src={pal_flag} alt="ECPC20" className="img2" />
          </div>
        </div>
        <div className="grow-up__content">
            <h1 className="grow-up__title">
                Grow up your problem solving skills with us from anywhere
            </h1>
            <p className="grow-up__description">
                We will help you with our experience  in the problem solving 
                field . From anywhere you can join the training and be always 
                updated through whatsapp or our facebook page.
            </p>
            <div className="grow-up__services">
                <ul className="grow-up__services-list">
                    <li className="grow-up__services-item">
                        <FiChevronRight size={40} className="grow-up__services-item-icon" />
                        <span className="grow-up__services-item-text">
                            New  topic every week
                        </span>
                    </li>
                    <li className="grow-up__services-item">
                        <FiChevronRight size={40} className="grow-up__services-item-icon" />
                        <span className="grow-up__services-item-text">
                            Contest  related  to  the  topic every week
                        </span>
                    </li>
                    <li className="grow-up__services-item">
                        <FiChevronRight size={40} className="grow-up__services-item-icon" />
                        <span className="grow-up__services-item-text">
                            Sessions  for explaining the topics 
                        </span>
                    </li>
                    <li className="grow-up__services-item">
                        <FiChevronRight size={40} className="grow-up__services-item-icon" />
                        <span className="grow-up__services-item-text">
                          Sheets  for  every  topic  with many  ideas
                        </span>
                    </li>
                </ul>
            </div>
            <button className="grow-up__button" onClick={() => navigate("/training")}>Join Now</button>
        </div>
    </section>
    );
};

export default GrowUp;