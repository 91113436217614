import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const FormInputSelect = ({fieldName, label, value, isEditing, options, formData, setEditingField, setFormData}) => {
    if (value === "not selected") {
      options.unshift("not selected");
    }

    return (
      <div className="form-group">
        <label className="form-label">{label}</label>
        <div className="form-input">
          <select
            name={fieldName.toLowerCase()}
            value={value}
            onChange={(e) =>
              setFormData({ ...formData, [fieldName]: e.target.value })
            }
            disabled={!isEditing}
          >
            {options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          {!isEditing && (
            <button onClick={() => setEditingField(fieldName)} className="edit-button">
              🖋️
            </button>
          )}
        </div>
      </div>
    );
};

const FormInputText = ({fieldName, label, value, isEditing, formData, setEditingField, setFormData}) => {
  
    return (
      <div className="form-group">
        <label className="form-label">{label}</label>
        <div className="form-input">
          <input
            className="test"
            type="text"
            name={fieldName.toLowerCase()}
            value={value}
            onChange={(e) =>
              setFormData({ ...formData, [fieldName]: e.target.value })
            }
            disabled={!isEditing}
          />
          {!isEditing && (
            <button onClick={() => setEditingField(fieldName)} className="edit-button">
              🖋️
            </button>
          )}
        </div>
      </div>
    );
  };

  const FormInputPhone = ({fieldName, label, value, isEditing, setEditingField, setFormData, formData}) => {
    return (
      <div className="form-group">
        <label className="form-label">{label}</label>
        <div className="form-input">
          <PhoneInput
            country={"eg"}
            value={value}
            onChange={(e) =>
              setFormData({ ...formData, [fieldName]: e })
            }
            disabled={!isEditing}
          />
          {!isEditing && (
            <button onClick={() => setEditingField(fieldName)} className="edit-button">
              🖋️
            </button>
          )}
        </div>
      </div>
    );
  };

  const FormInputDate = ({fieldName, label, value, isEditing, setEditingField, setFormData, formData}) => {
    if (value === null) {
      value = "";
    }
    return (
      <div className="form-group">
        <label className="form-label">{label}</label>
        <div className="form-input">
          <input
            type="date"
            name={fieldName.toLowerCase()}
            value={value}
            onChange={(e) =>
              setFormData({ ...formData, [fieldName]: e.target.value })
            }
            disabled={!isEditing}
          />
          {!isEditing && (
            <button onClick={() => setEditingField(fieldName)} className="edit-button">
              🖋️
            </button>
          )}
        </div>
      </div>
    );
  };


export {FormInputSelect, FormInputText, FormInputPhone, FormInputDate};

