import logo from "../../assets/second-logo.png";


const Intro = ()=>{
    return(
        <div className="upper-content">
            <img src={logo} alt="SCU Logo" />
            <p className="footer-text">
                Here you can learn about our community and be 
                a part of it !  If you are looking for a community that 
                provides you with a special training and also fun events and 
                contests then <span className="footer-text-span"> We are the one !</span>
            </p>
        </div>
    );
}

export default Intro ; 