import React, { useState } from 'react';
import Loader from '../../components/Loader/Loader';
import GoogleLogin from '../../components/Login/GoogleLogin/GoogleLogin';
import LoginForm from '../../components/Login/LoginForm/LoginForm';
import LoginIntro from '../../components/Login/LoginIntro/LoginIntro';
import "./Login.css" ; 
const Login = ({ setUser }) => {

  const [isLoading, setIsLoading] = useState(false); 
  
  return (
    <div className="login-page">
      {
        isLoading ? (
          <Loader />
        ) : (
          <>
              <LoginIntro>
                <LoginForm setUser={setUser}/>
                <GoogleLogin setIsLoading={setIsLoading} setUser={setUser}/>
              </LoginIntro>
          </>
        )
      }
    </div>
  );
};

export default Login;