import React, { useState , useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import axios from 'axios';
import Navbar from './components/Navbar/Navbar';
import Home from './pages/Home/Home';
import Training from '../src/pages/Training/Training';
import ECPC from './pages/ECPC/ECPC';
import Events from './pages/Events/Events';
import Achievements from './pages/Achievements/Achievements';
import Profile from './pages/Profile/Profile';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import FooterContainer from './components/Footer/FooterContainer';
import NotFoundPage from './pages/NotFound/Page404';
import { ToastContainer } from 'react-toastify';
import './App.css';
import ProtectRouter from './components/ProtectRouter/ProtectRouter';

function App() {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Check token validity from backend
    const token = localStorage.getItem('token');
    const checkTokenValidity = async () => {
      try {
        const res = await axios.post('https://icpc-official-website.onrender.com/api/check-token-existence/', { token });
          if (res.data.response === true) {
            setUser(res.data.data);
          } 
          else {
            localStorage.clear();
          }
        } catch (err) {
        } finally {
          setIsLoading(false);
        }
    };

    if (token) {
      checkTokenValidity();
    } else {
      setIsLoading(false);
    }
  }, []);

  return (
    <Router>
    {isLoading ? (
      <div className="app-loader">
        <div className="LoaderContainer"><span className="loader"></span></div>
      </div>
    ) : (
      <div className="App">
        <Navbar user={user} setUser={setUser} />
        <div className="content">
          <Routes>
            <Route path="*" element={<NotFoundPage />} />
            <Route path="/" element={<Home />} />
            <Route path="/training" element={<Training />} />
            <Route path="/ecpc" element={<ECPC />} />
            <Route path="/events" element={<Events />} />
            <Route path="/achievements" element={<Achievements />} />
            <Route
              path="/profile"
              element={
                <ProtectRouter childrenName="Profile">
                  <Profile
                    user={user}
                    setUser={setUser}
                  />
                </ProtectRouter>
              }
            />
            <Route
              path="/login"
              element={
                <ProtectRouter childrenName="Login">
                <Login
                  setUser={setUser}
                />
                </ProtectRouter>
              }
            />
            <Route
              path="/register"
              element={
                <ProtectRouter childrenName="Register">
                  <Register
                    setUser={setUser}
                  />
                </ProtectRouter>
              }
            />
          </Routes>
        </div>
        <FooterContainer />
      </div>
    )}
    <ToastContainer />
  </Router>
);
}

export default App;