import {FaFacebook, FaLinkedin} from "react-icons/fa";

const Contacts = ()=>{
    return(
        <div className="upper-content">
            <h3 className="footer-title">
                Links
            </h3>
            <div className="link-container">
                <FaFacebook className="link-icon" />
                <a href="https://www.facebook.com/ICPCSCU/" className="link" target="_blank" rel="noopener noreferrer">
                    https://www.facebook.com/ICPCSCU/
                </a>

            </div>
            <div className="link-container">
                <FaLinkedin className="link-icon" />
                <a href="https://www.linkedin.com/company/icpc-scu/" className="link" target="_blank" rel="noopener noreferrer">
                    https://www.linkedin.com/company/icpc-scu/
                </a>
            </div>
        </div>

    );
}

export default Contacts ; 