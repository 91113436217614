import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Terms from "../../Terms/Terms";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import validator from 'validator' 
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

const RegisterForm = ({setUser , name})=>{

    const navigate = useNavigate();
    const [isButtonLoading, setButtonIsLoading] = useState(false);
    const [formData, setFormData] = useState({
      username: "",
      password: "",
      confirmPassword: "",
      codeforcesHandle: "",
      whatsappNumber: "",
      gender: "",
    });
    const email = localStorage.getItem("email");
    const picture = localStorage.getItem("picture");
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
  
    const notify = (type , message) => {
      toast[type](message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  
    const validatePhone = (phone) => {
      if (validator.isMobilePhone('+' + phone)) {
        return true;
      }
      notify("error" , "Invalid phone number");
      return false;
    }
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      
      if (!validatePhone(formData.whatsappNumber)) {
        return;
      }
      
      if (formData.password !== formData.confirmPassword) {
        notify("error" , "Passwords don't match");
        return;
      }
      
      const data = {
        username: formData.username,
        codeforces_handle: formData.codeforcesHandle,
        whatsapp_number: formData.whatsappNumber,
        gender: formData.gender,
        email: email,
        picture: picture,
        real_name: name,
        password: formData.password,
      };
      
      setButtonIsLoading(true);
      try {
        const res = await axios.post('https://icpc-official-website.onrender.com/api/signup/', data, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
    
        localStorage.setItem('token', res.data.Token);
        localStorage.setItem('id', res.data.data.id);
        localStorage.setItem('user-data', JSON.stringify(res.data.data));
        setUser(res.data.data);
        navigate('/');
      }
      catch(err) {
        const errorArray = err.response.data.Error;
        if(Array.isArray(errorArray)) {
          errorArray.forEach(error => {
            notify("error" , error);
          });
        }
        else{
          if(err.response.data.Error){
            Object.values(errorArray).forEach(error => {
              notify("error" , error);
            });
          }
          else{
            notify("error" , "Something went wrong, please try again later");
          }
        }
      }
      finally {
        setButtonIsLoading(false); // Disable loading
      }
    };


    return (
        <form onSubmit={handleSubmit} className="register-form">
        <div className="register-form__item">
          <input
            className="form-item-input"
            type="text"
            name="username"
            value={formData.username}
            onChange={handleInputChange}
            required
          />
          <label className="form-item-label">Username</label>
        </div>

        <div className="register-form__item">
          <input
            className="form-item-input"
            type="password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            required
          />
          <label className="form-item-label">Password</label>
        </div>

        <div className="register-form__item">
          <input
            className="form-item-input"
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleInputChange}
            required
          />
          <label className="form-item-label">Confirm Password</label>
        </div>


        <div className="register-form__item">
          <input
            className="form-item-input"
            type="text"
            name="codeforcesHandle"
            value={formData.codeforcesHandle}
            onChange={handleInputChange}
            required
          />
          <label className="form-item-label">Codeforces Handle</label>
        </div>

        <div className="register-form__item">
          <PhoneInput
            className="form-item-phone"
            value={formData.whatsappNumber}
            onChange={(phone) => setFormData({...formData, whatsappNumber: phone})}
            inputProps={
              {
                required: true,
                name: 'whatsappNumber',
                placeholder: 'Whatsapp Number',
              }
            }
          />
        </div>

        <div className="register-form__item">
          <select
            className="form-item-input"
            name="gender"
            value={formData.gender}
            onChange={handleInputChange}
            required
          >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>

        <div className="form-group agree">
          <input type="checkbox" name="agree" required className="agree-checkbox" />
          <label className="agree-label">I agree to the</label>
          <Terms>
            <p className="terms-link">Terms and Conditions</p>
          </Terms>

        </div>
        <button type="submit" className="register-button" disabled={isButtonLoading}>
          {isButtonLoading ? 'Registering...' : 'Register'}
        </button>
      </form>
    );
}
export default RegisterForm ; 