import React from 'react';
import './Page404.css';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {

    const navigate = useNavigate();
    return (
      <div className="error-page">
        <img src="https://i.ibb.co/W6tgcKQ/softcodeon.gif" alt="404" className="error-page__img" />
        <h1 className="error-page__title">Whoops, We can't seem to find the resource you're looking for.</h1>
        <p className="error-page__text">Double check the URL or go back to the homepage.</p>
        <button className="error-page__button" onClick={() => navigate("/")}>Go to Home</button>
      </div>
    );
  };

export default NotFoundPage;