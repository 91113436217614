import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./LoginForm.css" ; 
const LoginForm = ({setUser})=>{
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isButtonLoading, setButtonIsLoading] = useState(false);
    const navigate = useNavigate();
  
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };
    
    const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonIsLoading(true); // Enable loading
    
    try {
        const res = await axios.post('https://icpc-official-website.onrender.com/api/login/', {
        email: email,
        password: password,
        }, {
        headers: {
            'Content-Type': 'application/json',
        },
        });
    
        // Handle the response
    
        localStorage.setItem('token', res.data.Token);
        localStorage.setItem('token', res.data.Token);
        localStorage.setItem('id', res.data.data.id);
        localStorage.setItem('user-data', JSON.stringify(res.data.data));
        setUser(res.data.data);
        navigate('/');
    } catch (err) {
        toast.error(err.response.data.Error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        });
    } finally {
        setButtonIsLoading(false); // Disable loading
    }
    };
    
    return(
        <form onSubmit={handleSubmit} className="login-form">
        <div className="login-form__item">
          <input
            type="text"
            className='form-item-input'
            onChange={handleEmailChange}
            required

          />
          <label className='form-item-label'>
            Email
          </label>
        </div>
        <div className="login-form__item">
          <input
            type="password"
            className='form-item-input'
            onChange={handlePasswordChange}
            required

          />
          <label className='form-item-label'>
            Password
          </label>
        </div>
        <div className="forgot-password">
          <a className = 'forget-pass-link' href="https://icpc-official-website.onrender.com/reset-password/">Forgot password?</a>
        </div>
        <button className="login-button" disabled={isButtonLoading}>
          {isButtonLoading ? 'Logging In...' : 'Login'}
        </button>
      </form>
    );
}

export default LoginForm ; 