import React from "react";
import "./Achievements.css";
import development from "../../assets/development.gif";
const Achievements = () => {
    return (
        <div className="achievements">
            <h1 className="achievements-title">Achievements are under development</h1>
            <img className="achievements-image" src={development} alt="development gif" />
        </div>
    );
}

export default Achievements;