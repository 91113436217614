import React, { useState, useMemo } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import VerifiyInput from "./VerifiyInput";

const VerficationData = ({ userData, setUserData, setIsVerified, verificationData, setVerificationData, isVerified }) => {

    const [isVerifying, setVerifying] = useState(false);

    const token = localStorage.getItem("token");
    const headers = useMemo(() => {
      const headersObj = {
              "Content-Type": "application/json",
          };
          if (token) {
              headersObj["Authorization"] = `Token ${token}`;
          }
  
          return headersObj;
      }, [token]);
      const notify = (type , message) => {
        toast[type](message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }


    const handleVerificationSaveClick = async () => {
    try {

        setVerifying(true);

        const res = await axios.post("https://icpc-official-website.onrender.com/api/verify-user/", verificationData, {
        headers: headers,
        });

        const data = {
        ...userData,
        is_verified: true,
        icpc_global: verificationData.icpc_global,
        national_ID: verificationData.national_ID,
        city: verificationData.city,
        year_of_enrollment: verificationData.year_of_enrollment,
        quaternary_name: verificationData.quaternary_name,
        };

        setUserData(data);
        setIsVerified(true);
        localStorage.setItem("profile-data", JSON.stringify(data));

        toast.info(res.data.response, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        });
    } catch (err) {
        console.error(err);
        if (err.response) {
        const errors = err.response.data.Error;
        Object.values(errors).forEach((value) => {
            notify("error", value);
        });
        } else {
            notify("error", "An error occurred");
        }
    } finally {
        setVerifying(false);
    }
    };

    return (
        <div className="verification">
            <h3 className="verification-title">Additional important information</h3>
            <div className="verification-data">
                <VerifiyInput fieldName="icpc_global" label="ICPC Global Username" userData={userData} verificationData={verificationData} setVerificationData={setVerificationData} isVerified={isVerified} />
                <VerifiyInput fieldName="national_ID" label="National ID" userData={userData} verificationData={verificationData} setVerificationData={setVerificationData} isVerified={isVerified} />
                <VerifiyInput fieldName="city" label="City" userData={userData} verificationData={verificationData} setVerificationData={setVerificationData} isVerified={isVerified} />
                <VerifiyInput fieldName="year_of_enrollment" label="Year of Enrollment" userData={userData} verificationData={verificationData} setVerificationData={setVerificationData} isVerified={isVerified} />
                <VerifiyInput fieldName="quaternary_name" label="Quaternary Name" userData={userData} verificationData={verificationData} setVerificationData={setVerificationData} isVerified={isVerified} />
            </div>
            <button onClick={handleVerificationSaveClick} className="verifiy-button" disabled>
                {isVerifying ? "Verifying..." : "Verify"}
            </button>
        </div>
    );
};

export default VerficationData;