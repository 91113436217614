import axios from "axios";
import React, { useEffect, useState } from "react";
import ECPCRegistration from "../ECPCRegisteration/ECPCRegisteration";
import Housing from "../Housing/Housing";
import Transportation from "../Transportation/Transportation";
import "../ECPCContainer/ECPCContainer.css" ; 
import Loader from "../../Loader/Loader";


const ECPCContainer = ()=>{
    const [ECPC, setECPC] = useState({});
    const [housing, setHousing] = useState({});
    const [transportation, setTransportation] = useState({});
    const [isLoading, setIsLoading] = useState(true);
  
    const token = localStorage.getItem('token');
    const headers = React.useMemo(() => {
      const headersObj = {
        'Content-Type': 'application/json',
      };
      if (token) {
        headersObj['Authorization'] = `Token ${token}`;
      }
      return headersObj;
    }, [token]);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const [ecpcResponse, housingResponse, transportationResponse] = await Promise.all([
            axios.get('https://icpc-official-website.onrender.com/api/ecpcq/', { headers }),
            axios.get('https://icpc-official-website.onrender.com/api/housing/', { headers }),
            axios.get('https://icpc-official-website.onrender.com/api/transportation/', { headers }),
          ]);
  
          setECPC(ecpcResponse.data[0]);
          setHousing(housingResponse.data[0]);
          setTransportation(transportationResponse.data[0]);
          setIsLoading(false);
        } catch (error) {
          console.error(error);
          setIsLoading(false);
        }
      };
  
      fetchData();
    }, [headers]);

    const isundefined = (level) => {
        if (typeof level === "object") {
            if (Object.keys(level).length === 0) {
                return true;
            }
            return false;
        }
        if (typeof level === "undefined") {
            return true;
        }
        return false;
    };


    return (
        <div className="ECPC">
          {
                isLoading ? (
                  <Loader />
                ) : (
                    isundefined(ECPC) && isundefined(housing) && isundefined(transportation) ? (
                        <h1> No data available at the moment. Stay tuned for updates! </h1>
                    ) : (
                      <div className="ECPC-container">
                            {ECPC && <ECPCRegistration ECPC={ECPC} setECPC={setECPC}/>}
                          <div className="ECPC-services">
                            {Housing && <Housing housing={housing} setHousing={setHousing}/>}
                            {Transportation && <Transportation transportation={transportation} setTransportation={setTransportation}/>}
                          </div>
                      </div>
                    )
                )
          }
            
        </div>
    );    
}

export default ECPCContainer ; 