import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import jwt_decode from 'jwt-decode';
import "./GoogleLogin.css" ; 

const GoogleLogin = ({setUser , setIsLoading})=>{
    const navigate = useNavigate() ; 

    useEffect(() => {
        /* global google */
        const handleCredentialResponse = async (response) => {
          setIsLoading(true);
          const userFromGoogle = jwt_decode(response.credential);
          if (userFromGoogle) {
            try {
              const res = await axios.post('https://icpc-official-website.onrender.com/api/check-user-existence/', { email: userFromGoogle.email });
              if (res.data.response === false) {
                localStorage.setItem('email', userFromGoogle.email);
                localStorage.setItem('picture', userFromGoogle.picture);
                localStorage.setItem('name', userFromGoogle.name);
                navigate('/register');
              } else {
                localStorage.setItem('token', res.data.token);
                localStorage.setItem('id', res.data.data.id);
                localStorage.setItem('user-data', JSON.stringify(res.data.data));
                setUser(res.data.data);
                navigate('/');
              }
            } catch (err) {
            }
          } else {
            setUser(null);
          }
          setIsLoading(false);
        };
        
        google.accounts.id.initialize({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          callback: handleCredentialResponse,
        });
    
        google.accounts.id.renderButton(document.getElementById('signInDiv'), {
          theme: 'outline',
          size: 'large',
          locale: 'en',
          width: '250',
          shape: 'circle',
          logo_alignment: 'left',
        });
      }, [setUser, navigate , setIsLoading]);

    return(
        <>
            <h2 className="line-with-text">Create an account</h2>
            <div id="signInDiv" />
        </>
    ) ;
}

export default GoogleLogin ;  