import logo from "../../assets/second-logo.png";
import ECPC from "../../assets/ECPC22.jpg";
import RegisterForm from "../../components/Register/RegisterForm/RegisterForm";
import "./Register.css" ; 

const Register = ({ setUser}) => {
  const name = localStorage.getItem("name");
  return (
    <div className="register-page">
      <img src={ECPC} alt="ECPC" className="register-ecpc-image" />
      <div className="register-container">
        <img src={logo} alt="SCU Logo" />
        <h1>Welcome {name}!</h1>
        <p>Let's continue filling in the data we need</p>
        <RegisterForm setUser={setUser} name={name} />
      </div>
    </div>
  );
};

export default Register ;