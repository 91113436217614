import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import axios from "axios";
import "./News.css";
import Loader from "../../Loader/Loader";

const News = () => {

  const [newsList, setNews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const backgroundColors = [
    "rgba(175, 42, 37, 1)",
    "rgba(248, 182, 46, 1)",
    "rgba(74, 129, 194, 1)",
  ];

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://icpc-official-website.onrender.com/api/news/"
      );
      setNews(response.data);
      setIsLoading(false);
    } catch (err) {
      if (err.response.status === 401) {
        localStorage.clear();
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const Card = ({ title, difinition, content, id }) => {
    const backgroundColor = backgroundColors[id % backgroundColors.length];
    return (
      <Popup
        trigger={
          <div className="card">
            <h2 className="card-title">{title}</h2>
            <h3 className="card-difinition">{difinition}</h3>
          </div>
        }
        modal
        contentStyle={{ 
          background: backgroundColor,
          borderRadius: "50px",
        }}
      >
        {(close) => (
          <div className="news__popup">
            <div className="news-modal">
              <button className="news-modal__close" onClick={close}>
                &times;
              </button>
              <div className="news-modal__title"> {title} </div>
              <div className="news-modal__content"> {content} </div>
            </div>
          </div>
        )}
      </Popup>
    );
  };

    return (
        <section className="news">
              <h1 className="news__title">News 🔥</h1>
              {isLoading ? (
                <Loader />
              ) : (
                newsList.length ? (
                    <div className="news__cards">
                    {newsList.map((news) => (
                      <Card
                        key={news.id}
                        title={news.title}
                        difinition={news.difinition}
                        content={news.content}
                        id={news.id}
                      />
                    ))}
                  </div>
                  ) : (
                    <h2>No news available at the moment. Stay tuned for updates!</h2>
                  )
              )}
            </section>
    );
}

export default News;