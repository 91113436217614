import React from 'react';
import ECPCregiester from '../../../assets/firstFirstACC.jpeg';
import PopupRegister from '../PopupRegister/PopupRegister';
import "../ECPCRegisteration/ECPCRegisteration.css" ; 


const ECPCRegistration = ({ECPC , setECPC}) => {
    return (
      <div className="ECPCRegistration">
        <div className="ECPCRegistration__content">
          <h1 className="ECPCRegistration__title">
            {ECPC.title} - {ECPC.is_open ? 'IS OPEN NOW!!' : 'COMING SOON!!'}
          </h1>
          <p className="ECPCRegistration__description">{ECPC.description}</p>
        </div>
        <div className="ECPCRegistration__button">
          {ECPC.is_open ? (
            <PopupRegister ECPC={ECPC} setECPC={setECPC}/>
          ) : (
            <button className="ECPC-closed">Closed 🔒</button>
          )}
        </div>
        <div className="ECPCRegistration__image-container">
          <img className="ECPCRegistration__image" src={ECPCregiester} alt="ECPCregiester" />
        </div>
      </div>
    );
};

export default ECPCRegistration ; 
