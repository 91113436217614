import {MdEmail, MdLocationOn} from "react-icons/md";

const Contacts = ()=>{
    return(
        <div className="upper-content">
            <h3 className="footer-title">
                Contacts
            </h3>
            <div className="link-container">
                <div className="link">
                    <MdLocationOn className="link-icon" />
                    <a href="https://maps.app.goo.gl/DDxFjhEwTwimP72G7" className="link" target="_blank" rel="noopener noreferrer">
                        Suez Canal University, Ismailia, Egypt
                    </a>
                </div>
            </div>
            <div className="link-container">
                <MdEmail className="link-icon" />
                <a href="mailto:icpcscu@gmail.com" className="link" target="_blank" rel="noopener noreferrer">
                    icpcscu@gmail.com
                </a>
            </div>
        </div>
    );
}

export default Contacts ; 