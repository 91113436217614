import { Link } from "react-router-dom";

const LoginButton = ()=>{
    return(
        <Link to="/login" className="login-link">
            <button className="login-btn">Login</button>
        </Link>
    );
}

export default LoginButton ; 